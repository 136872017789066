import React from "react";
import Avatar from "@material-ui/core/Avatar";
import LibraryBooks from "@material-ui/icons/LocalLibrary";
import { Tooltip } from "react-tippy";
import { useTipoPlanoSpotter, useUsuario } from "../../../hooks";
import { Flex } from "../..";

export default function Guia() {
  const TOPIC_INLINE_GUIA_EXACT_GERENTE_FULL = 113174;
  const TOPIC_INLINE_GUIA_EXACT_PRE_VENDEDOR_FULL = 113173;
  const TOPIC_INLINE_GUIA_EXACT_GERENTE_BASIC = 116079;
  const TOPIC_INLINE_GUIA_EXACT_PRE_VENDEDOR_BASIC = 116080;

  const { isGerente, isVendedor } = useUsuario();
  const isSpotterFull = useTipoPlanoSpotter();

  if (isVendedor) return null;

  const abreMenuInline = () => {
    if (isGerente) {
      return window.inline_manual_player.activateTopic(
        isSpotterFull ? TOPIC_INLINE_GUIA_EXACT_GERENTE_FULL : TOPIC_INLINE_GUIA_EXACT_GERENTE_BASIC
      );
    }

    return window.inline_manual_player.activateTopic(
      isSpotterFull ? TOPIC_INLINE_GUIA_EXACT_PRE_VENDEDOR_FULL : TOPIC_INLINE_GUIA_EXACT_PRE_VENDEDOR_BASIC
    );
  };

  return (
    <Flex alignItems="center" style={{ cursor: "pointer" }}>
      <Tooltip title="Guia Exact">
        <Avatar style={{ backgroundColor: "#F5845E", width: 30, height: 30 }} id="imGerente" onClick={abreMenuInline}>
          <LibraryBooks style={{ color: "#fff", width: 20, height: 20 }} />
        </Avatar>
      </Tooltip>
    </Flex>
  );
}
