import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip } from "react-tippy";
import { Flex } from "../..";
import iconeLogoNome from "../../../_common/icones/exactclubicon.svg";
import { useUsuario } from "../../../hooks";

export default function ExactClub() {
  const TOPIC_INLINE_EXACTCLUB_CONTRATADO = 116116;
  const TOPIC_INLINE_EXACTCLUB_NAO_CONTRATADO = 114045;
  const { featureToggles } = useUsuario();

  const abreMenuInline = () => {
    if (featureToggles.menuExactClub) {
      return window.inline_manual_player.activateTopic(TOPIC_INLINE_EXACTCLUB_CONTRATADO);
    }
    return window.inline_manual_player.activateTopic(TOPIC_INLINE_EXACTCLUB_NAO_CONTRATADO);
  };

  return (
    <Flex alignItems="center" style={{ cursor: "pointer" }}>
      <Tooltip title="Exact Club">
        <Avatar
          onClick={abreMenuInline}
          aria-label="recipe"
          src={iconeLogoNome}
          style={{
            width: 110,
            height: 40,
            borderRadius: 8,
          }}
        />
      </Tooltip>
    </Flex>
  );
}
