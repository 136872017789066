export const types = {
  SET_FIELDS: "QUERY_BUILDER/SET_FIELDS",
  SET_RESPONSES: "QUERY_BUILDER/SET_RESPONSE",
  MERGE_OPTIONS_INTO_FIELD: "QUERY_BUILDER/MERGE_OPTIONS_INTO_FIELD",
  MERGE_FIELD_INTO_RESPONSE: "QUERY_BUILDER/MERGE_FIELD_INTO_RESPONSE",
  UPDATE_QUERY: "QUERY_BUILDER/UPDATE_QUERY",
  ADD_GROUP: "QUERY_BUILDER/ADD_GROUP",
  REMOVE_GROUP: "QUERY_BUILDER/REMOVE_GROUP",
  ADD_RULE: "QUERY_BUILDER/ADD_RULE",

  REMOVE_RULE: "QUERY_BUILDER/REMOVE_RULE",
  CHANGE_GLOBAL_COMBINATOR: "QUERY_BUILDER/CHANGE_GLOBAL_COMBINATOR",
  CHANGE_GROUP_COMBINATOR: "QUERY_BUILDER/CHANGE_GROUP_COMBINATOR",
  CHANGE_RULE_FIELD: "QUERY_BUILDER/CHANGE_RULE_FIELD",
  CHANGE_RULE_VALUE: "QUERY_BUILDER/CHANGE_RULE_VALUE",
  CHANGE_RULE_RESPONSE: "QUERY_BUILDER/CHANGE_RULE_RESPONSE",
  SET_QUERY_CONFIG: "QUERY_BUILDER/SET_QUERY_CONFIG",
  RESET: "QUERY_BUILDER/RESET",
};

export const updateQuery = queryObj => ({
  type: types.UPDATE_QUERY,
  payload: queryObj,
});

export const changeGlobalCombinator = value => ({
  type: types.CHANGE_GLOBAL_COMBINATOR,
  payload: value,
});

export const setFields = fields => ({
  type: types.SET_FIELDS,
  payload: fields,
});

export const setResponses = responses => ({
  type: types.SET_RESPONSES,
  payload: responses,
});

export const mergeOptionsIntoField = (fieldValue, options) => ({
  type: types.MERGE_OPTIONS_INTO_FIELD,
  payload: {
    fieldValue,
    options,
  },
});

export const mergeFieldIntoResponse = x => ({
  type: types.MERGE_FIELD_INTO_RESPONSE,
  payload: {
    x,
  },
});

export const addGroup = () => ({
  type: types.ADD_GROUP,
});

export const removeGroup = groupIndex => ({
  type: types.REMOVE_GROUP,
  payload: groupIndex,
});

export const changeGroupCombinator = (groupIndex, value) => ({
  type: types.CHANGE_GROUP_COMBINATOR,
  payload: {
    groupIndex,
    value,
  },
});

export const addRule = groupIndex => ({
  type: types.ADD_RULE,
  payload: groupIndex,
});

export const removeRule = (groupIndex, ruleIndex, fieldValue) => ({
  type: types.REMOVE_RULE,
  payload: {
    groupIndex,
    ruleIndex,
    fieldValue,
  },
});

export const changeRuleField = (groupIndex, ruleIndex, fieldValue) => ({
  type: types.CHANGE_RULE_FIELD,
  payload: {
    groupIndex,
    ruleIndex,
    fieldValue,
  },
});

export const changeRuleResponse = (groupIndex, ruleIndex, response) => ({
  type: types.CHANGE_RULE_RESPONSE,
  payload: {
    groupIndex,
    ruleIndex,
    response,
  },
});

export const changeRuleValue = (groupIndex, ruleIndex, value) => ({
  type: types.CHANGE_RULE_VALUE,
  payload: {
    groupIndex,
    ruleIndex,
    value,
  },
});

export const setQueryConfig = config => ({
  type: types.SET_QUERY_CONFIG,
  payload: config,
});

export const reset = () => ({
  type: types.RESET,
});
