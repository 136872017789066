import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { IconButton, Menu, MenuList, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useAnchorMenu } from "../../../hooks/useAnchorMenu";
import { maybeCallback } from "../../utils/fp";

MoreOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      icon: PropTypes.node,
    })
  ),
  willOpen: PropTypes.func,
  didClose: PropTypes.func,
};

function MoreOptions({ options, willOpen, didClose, ...props }) {
  const { buttonRef, anchorEl, openMenu, closeMenu } = useAnchorMenu(null);

  const open = () => {
    maybeCallback(willOpen)();
    openMenu();
  };

  const close = () => {
    closeMenu();
    maybeCallback(didClose)();
  };

  return (
    <Fragment>
      <IconButton buttonRef={buttonRef} onClick={open} disableRipple={false} {...props}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        disableAutoFocusItem
        onClose={close}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MenuList>
          {options.map((option, i) => (
            <MenuItem
              disabled={option.disabled}
              onClick={() => {
                closeMenu();
                option.onClick();
              }}
              key={i}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Fragment>
  );
}

export default MoreOptions;
