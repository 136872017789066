import React, { useState, useCallback } from "react";
import { Tooltip, IconButton, withStyles, Popover, Divider } from "@material-ui/core";
import { useIntl } from "react-intl";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import axios from "axios";

import { Flex, Loading, Tipografia } from "../..";
import { useUsuario, useEvent } from "../../../hooks";
import { useEditarAtividade } from "../../../pages/atividade/components/EditarAtividade";
import { useConcluirAtividade } from "../../../pages/atividade/components/ConcluirAtividade";
import ModalAtividadeNewCont from "../../../pages/atividade/components/ModalAtividadeNewCont";
import Atividades from "../../../pages/atividades/ListaAtividades/Atividades";
import ModalConcluirAtividadeEncadeada from "../../../pages/atividades/modals/ModalConcluirAtividadeEncadeada";
import FeedbackRollout from "../../../components/FeedbackRollout";
import LigacaoContatosLead from "../../../pages/detalhes-lead/components/LigacaoContatosLead/LigacaoContatosLead";

function AtividadesNavbar({ classes }) {
  const intl = useIntl();
  const { isGerente, id: idUsuario, isPreVendedor } = useUsuario();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalLigacaoIsOpen, setModalLigacaoIsOpen] = useState([]);
  const [atividadeSelecionada, setAtividadeSelecionada] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listaAtividades, setListaAtividades] = useState([]);
  const { editarAtividade, modalEditarAtividadeProps } = useEditarAtividade();
  const { concluirAtividade, modalConcluirAtividadeProps, verificaAcaoEncadeadaWF } = useConcluirAtividade();

  const toggleModalLigacao = (atividade, abrir) => {
    if (abrir) {
      setAtividadeSelecionada(atividade);
      setModalLigacaoIsOpen(true);
    } else {
      setModalLigacaoIsOpen(false);
      setAtividadeSelecionada(null);
    }
  };

  const handleClick = async event => {
    setAnchorEl(event.currentTarget);
    await buscar();
  };

  const buscar = async () => {
    setLoading(true);
    const resp = await axios.get(`/api/pipeline/atividade/AtividadesNavbar?id=${idUsuario}`);
    setListaAtividades(resp.data);
    setLoading(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickConcluir = atividade =>
    ModalConcluirAtividadeEncadeada(atividade, concluirAtividade, intl, verificaAcaoEncadeadaWF);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const reloadHandler = useCallback(() => buscar());

  useEvent("atividadesNavbar/lista/reload", reloadHandler);

  return (
    <>
      {isPreVendedor && (
        <Flex alignItems="center">
          <Tooltip title={intl.formatMessage({ defaultMessage: "Abrir atividades" })}>
            <IconButton id="btn-atividades-navbar" className={classes.button} onClick={handleClick}>
              <PlaylistPlayIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        </Flex>
      )}
      {modalLigacaoIsOpen &&
        atividadeSelecionada && (
          <LigacaoContatosLead
            onClose={() => {
              toggleModalLigacao(null, false);
            }}
            leadId={atividadeSelecionada.lead?.id?.toString()}
          />
        )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Flex flexDirection="column" className={classes.content}>
          <Flex className={classes.item}>
            <Tipografia tipo="tituloCard">{intl.formatMessage({ defaultMessage: "Atividades" })}</Tipografia>
            <FeedbackRollout classe="feedback-trasnf-playlist" />
          </Flex>
          <Divider className={classes.divider} fullwidth />
          {loading && <Loading isLoading />}
          {!loading && (
            <>
              {listaAtividades.length === 0 && (
                <Tipografia className={classes.noActivity}>
                  {intl.formatMessage({ defaultMessage: "Não há atividades para exibir" })}
                </Tipografia>
              )}
              <Atividades
                tipoAgrupamento={0}
                atividades={listaAtividades}
                isGerente={isGerente}
                onClickEditarAtividade={editarAtividade}
                toggleModalLigacao={toggleModalLigacao}
                onClickConcluirAtividade={atividade => handleClickConcluir(atividade)}
                eventReloadName="atividadesNavbar/lista/reload"
                ocultarDadosNavbar
                linkLeadNavbar
              />
            </>
          )}
        </Flex>
      </Popover>
      {modalEditarAtividadeProps.open && (
        <ModalAtividadeNewCont
          {...modalEditarAtividadeProps}
          callback={success => success && document.dispatchEvent(new Event("atividadesNavbar/lista/reload"))}
        />
      )}
      {modalConcluirAtividadeProps.open && (
        <ModalAtividadeNewCont
          {...modalConcluirAtividadeProps}
          callback={success => success && document.dispatchEvent(new Event("atividadesNavbar/lista/reload"))}
        />
      )}
    </>
  );
}

export default withStyles(() => ({
  icon: { color: "#fff", fontSize: 34 },
  content: { width: 600, maxHeight: 712 },
  item: { padding: 8 },
  button: { padding: 0 },
  noActivity: { textAlign: "center", padding: 24 },
}))(AtividadesNavbar);
