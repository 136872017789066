import PropTypes from "prop-types";

import { compose } from "recompose";
import { withRouter } from "react-router";
import withUsuario from "../../../_common/hocs/withUsuario/withUsuario";

const VerificaPermissao = ({
  isGerente,
  isPreVendedor,
  claimType,
  claimValue,
  redirect,
  children,
  usuario,
  history,
}) => {
  if (usuario.isRequesting) {
    return children;
  }

  if (isGerente && usuario.isGerente) {
    return children;
  }

  if (isPreVendedor && usuario.isPreVendedor && !claimType && !claimValue) {
    return children;
  }

  if (
    usuario.isPreVendedor &&
    claimType &&
    claimValue &&
    usuario.permissoes.find(
      permissao =>
        permissao.claimType.toUpperCase() === claimType.toUpperCase() &&
        permissao.claimValue.toUpperCase() === claimValue.toUpperCase()
    )
  ) {
    return children;
  }

  if (redirect) {
    history.push(`/spotter/acesso-restrito/sem-permissao-acesso/`);
  }
  return null;
};

VerificaPermissao.propTypes = {
  gerente: PropTypes.bool,
  preVendedor: PropTypes.bool,
  tipo: PropTypes.string,
  claimValue: PropTypes.string,
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
};

VerificaPermissao.defaultProps = {
  gerente: null,
  preVendedor: null,
  claimType: null,
  claimValue: null,
  redirect: true,
};

const enhancer = compose(
  withRouter,
  withUsuario
);
export default enhancer(VerificaPermissao);
