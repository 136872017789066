import React from "react";
import { Flex, Tag } from "../..";

export default function TagSpotterBasic() {
  return (
    <Flex style={{ width: 120, height: 30, justifyContent: "center", alignSelf: "center" }}>
      <Tag descricao={"Spotter Basic"} />
    </Flex>
  );
}
