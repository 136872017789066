import { COMBINATORS, DEFAULT_CONFIG } from "../constants";

const AND = COMBINATORS[0].value;
const OR = COMBINATORS[1].value;

export const DEFAULT_RULE = () => ({
  id: null,
  field: "field-default",
  value: null,
  response: null,
  operator: 0,
});

export const DEFAULT_GROUP = () => ({
  id: null,
  combinator: OR,
  rules: [DEFAULT_RULE()],
});

export const INITIAL_QUERY = () => ({
  globalCombinator: AND,
  groups: [DEFAULT_GROUP()],
});

export const initialState = () => ({
  query: INITIAL_QUERY(),
  fieldsOptions: [],
  fieldsResponses: [],
  config: DEFAULT_CONFIG(),
});
