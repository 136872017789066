import { IconButton, Paper } from "@material-ui/core";
import { KeyboardArrowLeft } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { Tipografia, Flex } from "../../../_common";
import injectSheet from "../../hocs/injectSheet/injectSheet";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import TituloEditavel from "../TituloEditavel/TituloEditavel";

const styles = {
  root: {
    padding: [16, 29, 0, 29],
    marginBottom: 24,
    position: "relative",
  },
  botaoVoltar: {
    borderRadius: "50%",
    backgroundColor: "rgba(0, 0, 0, .12) !important",
    height: "28px !important",
    width: "28px !important",
    padding: "0 !important",
  },
  iconeBotaoVoltar: {
    color: "rgba(0, 0, 0, .38)",
    marginRight: 2,
    height: "28px !important",
    width: "28px !important",
  },
  titulo: {
    marginLeft: 8,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  subheader: {
    overflow: "hidden",
  },
  breadcrumb: {
    marginTop: 2,
    marginBottom: 18,
  },
  tabs: {
    marginLeft: -29,
    display: "flex",
    alignItems: "center",
  },
  linkVersaoClassica: { marginLeft: "auto" },
};

const Subheader = ({
  classes,
  titulo,
  breadcrumb,
  children,
  linkVersaoClassica,
  isTituloEditavel,
  placeholderTitulo,
  onChangeTituloEditavel,
  onBlurTituloEditavel,
  hasErrorTituloEditavel,
  handleBotaoVoltar,
  hideBotaoVoltar,
  tituloMaxLength,
}) => {
  const previousPage = React.useMemo(
    () =>
      breadcrumb
        ?.slice()
        .reverse()
        .find(({ url }) => !!url),
    [breadcrumb]
  );

  return (
    <Paper elevation={1} className={classes.root}>
      <Flex alignItems="center" className={classes.subheader}>
        {!hideBotaoVoltar &&
          previousPage && (
            <Link to={previousPage.url}>
              <IconButton className={classes.botaoVoltar} onClick={handleBotaoVoltar}>
                <KeyboardArrowLeft className={classes.iconeBotaoVoltar} />
              </IconButton>
            </Link>
          )}
        {!isTituloEditavel && (
          <Tipografia className={classes.titulo} tipo="titulo1" id="tituloSubheader">
            {titulo}
          </Tipografia>
        )}
        {isTituloEditavel && (
          <TituloEditavel
            value={titulo}
            placeholder={placeholderTitulo}
            onChange={onChangeTituloEditavel}
            onBlur={onBlurTituloEditavel}
            hasErrorTituloEditavel={hasErrorTituloEditavel}
            maxLength={tituloMaxLength}
          />
        )}
        {linkVersaoClassica && (
          <a className={classes.linkVersaoClassica} href={linkVersaoClassica}>
            Acessar versão clássica
          </a>
        )}
      </Flex>
      {breadcrumb && <Breadcrumb className={classes.breadcrumb} valores={breadcrumb} />}
      <div className={classes.tabs}>{children}</div>
    </Paper>
  );
};

Subheader.propTypes = {
  /** @ignore */
  classes: PropTypes.object,
  /** Titulo exibido no subheader */
  titulo: PropTypes.string.isRequired,
  /** Tamanho máximo do título durante a edição */
  tituloMaxLength: PropTypes.number,
  /** Array de breadcrumbs a serem exibidos */
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      /** Título do breadcrumb */
      titulo: PropTypes.string.isRequired,
      /** Url do breadcrumb */
      url: PropTypes.string,
      /** Indica se a url é externa (`httm://google.com`) ou interna (url do front do react) */
      urlExterna: PropTypes.bool,
    })
  ),
  /** Conteúdo a ser rendereizado. */
  children: PropTypes.node,
  /** @ignore */
  linkVersaoClassica: PropTypes.string,
  /** Define o tipo do título do subheader como editável */
  isTituloEditavel: PropTypes.bool,
  /** Texto exibido no input quando vazio */
  placeholderTitulo: PropTypes.string,
  /** Função que altera o estado/valor do componente título editável */
  onChangeTituloEditavel: PropTypes.func,
  /** Função chamada no evento onBlur do textbox do título editável */
  onBlurTituloEditavel: PropTypes.func,
  /** Define o estilo do título editável como erro (cor vermelha) */
  hasErrorTituloEditavel: PropTypes.bool,
  handleBotaoVoltar: PropTypes.func,
  /** Mostra botão de voltar */
  hideBotaoVoltar: PropTypes.bool,
};

export default injectSheet(styles)(Subheader);
