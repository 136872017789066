import styled, { css } from "styled-components";

styled.div = styled("div").attrs({ _include: `cellIndex` });

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 13px;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 5px 2px;
  user-select: none;
  width: 100%;
  white-space: nowrap;
  max-width: ${({ size }) => size};
  justify-content: ${({ justify }) => justify || "flex-start"};
  cursor: ${({ sortable }) => (sortable ? "pointer" : "unset")};
  color: ${({ theme }) => theme.palette.text.darkSecondaryText};

  > svg {
    margin-left: 5px;
    font-size: 16px;
    color: inherit;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.icons.darkFocusedIcon};
  }
`;

export const TableBody = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ headers }) =>
    headers.map(
      ({ size, justify }, index) =>
        css`
          div[cell-index="${index}"] {
            max-width: ${size};
            min-width: ${size};
            padding-right:4px;
            position:relative;
            ${justify &&
              css`
                 {
                  justify-content: ${justify};
                }
              `}
          }
        `
    )};
`;

export const TableRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  transition: all 0.15s ease;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.backgroundRowHover};
    .tablelist-actions {
      visibility: visible;
    }
  }

  .tablelist-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.backgroundRowHover};
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
  }
`;
