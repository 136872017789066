import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withTheme } from "react-jss";
import { IconButton } from "material-ui";
import cn from "classnames";

import { NavigationArrowDownward, NavigationArrowUpward } from "material-ui/svg-icons";
import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

const styles = {
  containerIcone16: {
    width: "32px !important",
    height: "32px !important",
    padding: "8px !important",
  },
  icone16: {
    width: "16px !important",
    height: "16px !important",
  },
};

const OrdenarLista = ({ orders, theme, classes, className }) => (
  <IconButton iconStyle={styles.icone16} className={cn(classes.containerIcone16, className)}>
    {orders === "asc" ? (
      <NavigationArrowDownward
        color={theme.palette.icons.darkActiveIcon}
        hoverColor={theme.palette.icons.darkFocusedIcon}
      />
    ) : (
      <NavigationArrowUpward
        color={theme.palette.icons.darkActiveIcon}
        hoverColor={theme.palette.icons.darkFocusedIcon}
      />
    )}
  </IconButton>
);

OrdenarLista.propTypes = {
  orders: PropTypes.string.isRequired,
  theme: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
};

const enhance = compose(
  withTheme,
  injectSheet(styles)
);
export default enhance(OrdenarLista);
