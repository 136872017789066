import React from "react";
import PropTypes from "prop-types";
import { SnackbarProvider } from "notistack";
import { withStyles } from "@material-ui/core";

SnackProvider.propTypes = { children: PropTypes.node };
function SnackProvider({ children, classes }) {
  return (
    <SnackbarProvider hideIconVariant maxSnack={7} classes={{ root: classes.snack, variantInfo: classes.snackbar }}>
      {children}
    </SnackbarProvider>
  );
}
export default withStyles({
  snack: {
    left: 56,
  },
  snackbar: {
    backgroundColor: "rgba(0, 0, 0, 0.87)",
    borderRadius: "2px",
    opacity: 1,
  },
})(SnackProvider);
