import { defineMessage } from "react-intl";

import { appIntl } from "../../../_common/containers/ConnectedIntlProvider";

export const NO_DATA = "QueryBuilderDefaults:no-data";

export const DEFAULT_CONFIG = () => ({
  MAX_GROUPS: 2,
  MAX_RULES: 2,
});

export const COMBINATORS = [
  {
    value: "AND",
    description: defineMessage({ defaultMessage: "E" }),
    alternativeDescription: defineMessage({ defaultMessage: "todos os" }),
    intValue: 0,
  },
  {
    value: "OR",
    description: defineMessage({ defaultMessage: "OU" }),
    alternativeDescription: defineMessage({ defaultMessage: "qualquer um dos" }),
    intValue: 1,
  },
];

export const OPERATORS = {
  equals: 0,
};

const defaultConfig = DEFAULT_CONFIG();

const createHelpMessage = prepend => {
  const intl = appIntl();
  return `${intl.formatMessage(prepend)} ${intl.formatMessage({
    defaultMessage:
      "Em breve serão disponibilizados pacotes de expansão e você será notificado. Ainda tem dúvidas? Consulte nossa Central de Ajuda.",
  })}`;
};

export const maxRulesHelpMessage = ({ maxRules, maxRulesReached }) =>
  maxRulesReached && maxRules <= defaultConfig.MAX_RULES
    ? createHelpMessage(
        defineMessage({ defaultMessage: "A inserção de Critérios está limitada em dois por Segmentação de Critérios." })
      )
    : "";

export const maxGroupsHelpMessage = ({ maxGroups, maxGroupsReached }) =>
  maxGroupsReached && maxGroups <= defaultConfig.MAX_GROUPS
    ? createHelpMessage(
        defineMessage({ defaultMessage: "A inserção de Segmentação está limitada em duas por Lista Dinâmica." })
      )
    : "";
