import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Paper from "material-ui/Paper";

import { Flex, Tipografia, Divider } from "../..";

const styles = {
  cabecalho: {
    height: 56,
    backgroundColor: "rgba(250, 250, 250, 1)",
    alignItems: "center",
    paddingLeft: 16,
  },
  painel: { margin: 12, minHeight: 100, backgroundColor: "#fff" },
  paper: { minHeight: 100, height: "100%" },
  divAbas: { marginLeft: "auto", paddingRight: 16 },
};

const Painel = ({ largura, titulo, abas, classes, children, estatistica, tituloStyle, actions = null, hasBadge }) => (
  <div className={classes.painel} style={{ width: largura || "" }}>
    <Paper zDepth={1} className={classes.paper}>
      <Flex className={classes.cabecalho}>
        <Tipografia className={tituloStyle} tipo="tituloCard">
          {titulo}
        </Tipografia>
        {(estatistica || actions) && (
          <div className={hasBadge ? "" : classes.divAbas}>
            {actions}
            {estatistica && (
              <Tipografia tipo="notaLegendas" cor="darkSecondaryText">
                {estatistica}
              </Tipografia>
            )}
          </div>
        )}
        {abas && <div className={classes.divAbas}>{abas.map(aba => aba)} </div>}
      </Flex>
      <Divider style={{ width: "100%", height: 1 }} />

      {children}
    </Paper>
  </div>
);

Painel.propTypes = {
  /** Conteúdo a ser renderizado dentro do painel. */
  children: PropTypes.node.isRequired,
  /** Largura do painel. */
  largura: PropTypes.string,
  /** Título do painel. */
  titulo: PropTypes.string.isRequired,
  /** Array de abas apresentadas no canto direito do cabeçalho */
  abas: PropTypes.array,
  /** Um dado secunário apresentado no canto direito do cabeçalho */
  estatistica: PropTypes.node,
  /** Um dado secunário apresentado no canto direito do cabeçalho */
  actions: PropTypes.node,
  /** @ignore */
  classes: PropTypes.object.isRequired,
  tituloStyle: PropTypes.string,
  hasBadge: PropTypes.bool,
};

Painel.defaultProps = {
  largura: undefined,
};

export default withStyles(styles)(Painel);
