import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";

export const Container = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  > svg {
    color: #7d7d7d;
  }

  div[role="presentation"] {
    width: 100% !important;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  > svg {
    color: #7d7d7d;
    position: absolute;
  }

  > input {
    height: 100%;
    border: none;
    outline: none;
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    margin-left: 15px;
    padding: 0 15px 0 22px;

    &::placeholder {
      color: #999999dd;
    }
  }
`;

export const Placeholder = styled.p`
  color: #bcbcbc;
  margin: 0 0 -1px 0;
`;

export const CloseButton = styled(CloseIcon)`
  right: -3px;
  cursor: pointer;
`;

export const ListItens = styled.ul`
  width: 100%;
  padding-top: 45px;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 120px;
`;
