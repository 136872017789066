import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { TextField } from "formik-material-ui";

import { menuScrollableStyle } from "./style";

/**
 * Select do Formik-Material-UI com scroll horizontal
 */
function SelectScrollableFormik({ classes, SelectProps = {}, ...props }) {
  return (
    <TextField
      select
      SelectProps={{
        MenuProps: {
          classes: {
            paper: classes.menu,
          },
        },
        ...SelectProps,
      }}
      {...props}
    />
  );
}

SelectScrollableFormik.propTypes = {
  SelectProps: PropTypes.object,
};

export default withStyles({ menu: menuScrollableStyle })(SelectScrollableFormik);
