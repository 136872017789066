import React from "react";
import PropTypes from "prop-types";

import Popper from "@material-ui/core/Popper";
import injectSheet from "../../hocs/injectSheet/injectSheet";

import MultiSelectInput from "../MultiSelectInput/MultiSelectInput";
import MultiSelectItemsList from "../MultiSelectItemsList/MultiSelectItemsList";

export const styles = {
  renderRoot: {
    display: "flex",
    cursor: "default",
    userSelect: "none",
    width: ({ width }) => (typeof width === "number" ? `${width}px !important` : `${width} !important`),
  },
  popper: {
    zIndex: 1601,
    marginTop: -50,
    marginBottom: -50,
  },
};

const MultiSelectRender = ({
  items,
  textDisplayInput,
  floatingLabelText,
  isOpen,
  handleChangeMenuIsOpen,
  searchTerm,
  handleOnChangeSearchTerm,
  allChecked,
  width,
  getItemProps,
  substituirPlaceholder,
  urlCriarNovo,
  urlBuscar,
  callbackCriarNovo,
  classes,
}) => (
  <div className={classes.renderRoot}>
    <MultiSelectInput
      id={floatingLabelText}
      textDisplayInput={textDisplayInput}
      floatingLabelText={floatingLabelText}
      isOpen={isOpen}
      handleChangeMenuIsOpen={handleChangeMenuIsOpen}
      width={width}
    />
    {isOpen ? (
      <Popper
        className={classes.popper}
        open={isOpen}
        placement="bottom-start"
        disablePortal
        modifiers={{
          preventOverflow: { enabled: true, boundariesElement: "viewport" },
          flip: { enabled: true, boundariesElement: "viewport", behavior: "flip" },
        }}
        anchorEl={() => document.getElementById(floatingLabelText)}
      >
        <MultiSelectItemsList
          items={items}
          floatingLabelText={floatingLabelText}
          isOpen={isOpen}
          searchTerm={searchTerm}
          handleOnChangeSearchTerm={handleOnChangeSearchTerm}
          allChecked={allChecked}
          minWidth={width}
          autowidth
          getItemProps={getItemProps}
          substituirPlaceholder={substituirPlaceholder}
          urlCriarNovo={urlCriarNovo}
          urlBuscar={urlBuscar}
          callbackCriarNovo={callbackCriarNovo}
        />
      </Popper>
    ) : null}
  </div>
);

MultiSelectRender.propTypes = {
  /** Items do componente. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      descricao: PropTypes.string.isRequired,
      checked: PropTypes.bool,
    })
  ).isRequired,
  /** Texto para ser exibido no input */
  textDisplayInput: PropTypes.string.isRequired,
  /** Label do input. */
  floatingLabelText: PropTypes.string,
  /** Indica se o menu está aberto, se ´true´ = aberto . */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Função chamada para modificar o estado do componente: aberto = ´true´, fechado = ´false´.
   * @param {bool} open
   */
  handleChangeMenuIsOpen: PropTypes.func.isRequired,
  /** Texto utilizado como busca na lista dos itens */
  searchTerm: PropTypes.string,
  /**
   * Função chamada para modificar o texto da busca na lista dos itens.
   * @param {string} value
   */
  handleOnChangeSearchTerm: PropTypes.func,
  allChecked: PropTypes.bool,
  /** Largura do componente */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** @ignore */
  getItemProps: PropTypes.func.isRequired,
  /** @ignore */
  substituirPlaceholder: PropTypes.bool,
  urlCriarNovo: PropTypes.string,
  urlBuscar: PropTypes.string,
  callbackCriarNovo: PropTypes.func,
  classes: PropTypes.object,
};

export default injectSheet(styles)(MultiSelectRender);
