import React from "react";

import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

import injectSheet from "../../hocs/injectSheet/injectSheet";

const styles = theme => ({
  titulo1: {
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: 28,
  },
  titulo2: {
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: 24,
  },
  tituloCard: {
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: 20,
  },
  navbar: {
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: 16,
  },
  subtituloConteudo: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 16,
  },
  textoSidebar: {
    fontFamily: "Roboto",
    fontSize: 14,
  },
  cardIndicator: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: 28,
  },
  botoes: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 14,
  },
  notaLegendas: {
    fontFamily: "Roboto",
    fontSize: 12,
  },
  tooltip: {
    fontFamily: "Roboto",
    fontSize: 11,
  },
  darkPrimaryText: {
    color: theme.palette.text.darkPrimaryText,
  },
  darkSecondaryText: {
    color: theme.palette.text.darkSecondaryText,
  },
  darkHintText: {
    color: theme.palette.text.darkHintText,
  },
  darkDividers: {
    color: theme.palette.text.darkDividers,
  },
  lightPrimaryText: {
    color: theme.palette.text.lightPrimaryText,
  },
  lightSecondaryText: {
    color: theme.palette.text.lightSecondaryText,
  },
  lightHintText: {
    color: theme.palette.text.lightHintText,
  },
  lightDividers: {
    color: theme.palette.text.lightDividers,
  },
  link: {
    color: "#3d69a0",
    "&:hover": {
      textDecoration: "none",
      color: "#3d69a0",
    },
  },
  italico: {
    fontStyle: "italic",
  },
  darkLink: {
    color: theme.palette.text.darkSecondaryText,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.text.darkPrimaryText,
    },
  },
});

const SpanHtml = ({ children, to, ...props }) => <span {...props}>{children}</span>;

SpanHtml.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

SpanHtml.defaultProps = {
  to: null,
};

const AHtml = ({ children, to, ...props }) => (
  <a href={to} {...props}>
    {children}
  </a>
);

AHtml.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element, PropTypes.number]).isRequired,
  to: PropTypes.string,
};

AHtml.defaultProps = {
  to: undefined,
};

const Tipografia = ({
  classes,
  tipo,
  children,
  className,
  url,
  urlExterna,
  cor,
  italico,
  sheet,
  theme,
  darkLink,
  ...rest
}) => {
  let Wrapper;
  if (url && !urlExterna) {
    Wrapper = Link;
  } else if (url) {
    Wrapper = AHtml;
  } else {
    Wrapper = SpanHtml;
  }

  const corNormalizada = cor || "darkPrimaryText";

  return (
    <Wrapper
      to={url}
      {...rest}
      className={classNames(
        {
          [classes.titulo1]: tipo === "titulo1",
          [classes.titulo2]: tipo === "titulo2",
          [classes.tituloCard]: tipo === "tituloCard",
          [classes.navbar]: tipo === "navbar",
          [classes.subtituloConteudo]: tipo === "subtituloConteudo",
          [classes.textoSidebar]: tipo === "textoSidebar",
          [classes.cardIndicator]: tipo === "cardIndicator",
          [classes.botoes]: tipo === "botoes",
          [classes.notaLegendas]: tipo === "notaLegendas",
          [classes.tooltip]: tipo === "tooltip",
          [classes.darkPrimaryText]: corNormalizada === "darkPrimaryText",
          [classes.darkSecondaryText]: corNormalizada === "darkSecondaryText",
          [classes.darkHintText]: corNormalizada === "darkHintText",
          [classes.darkDividers]: corNormalizada === "darkDividers",
          [classes.lightPrimaryText]: corNormalizada === "lightPrimaryText",
          [classes.lightSecondaryText]: corNormalizada === "lightSecondaryText",
          [classes.lightHintText]: corNormalizada === "lightHintText",
          [classes.lightDividers]: corNormalizada === "lightDividers",
          [classes.link]: !cor && !!url && !darkLink,
          [classes.italico]: italico,
          [classes.darkLink]: darkLink,
        },
        className
      )}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

Tipografia.propTypes = {
  tipo: PropTypes.oneOf([
    "titulo1",
    "titulo2",
    "tituloCard",
    "navbar",
    "subtituloConteudo",
    "textoSidebar",
    "cardIndicator",
    "botoes",
    "notaLegendas",
    "tooltip",
  ]),
  cor: PropTypes.oneOf([
    "darkPrimaryText",
    "darkSecondaryText",
    "darkHintText",
    "darkDividers",
    "lightPrimaryText",
    "lightSecondaryText",
    "lightHintText",
    "lightDividers",
  ]),
  /** @ignore */
  classes: PropTypes.object,
  /**
   * Nome da classe css a ser aplicada no componente. <br />
   * Se utilizar o `injectsheet`: usar `{claases.nome_da_classe}`
   */
  className: PropTypes.string,
  /** Itens a serem renderizados. */
  children: PropTypes.node,
  /** URL para ser direcionada nas rotas do `REACT` */
  url: PropTypes.string,
  /** Se verdadeiro, monta um link com a tag `a`  */
  urlExterna: PropTypes.bool,
  /** Se verdadeiro, estiliza o texto com `italico`  */
  italico: PropTypes.bool,
  /** Cria um link com cor escura, invés do azul padrão */
  darkLink: PropTypes.bool,
  /** @ignore */
  sheet: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
};

Tipografia.defaultProps = {
  tipo: "textoSidebar",
  className: null,
  url: null,
  urlExterna: null,
};

export default injectSheet(styles)(Tipografia);
