import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import { Flex } from "../..";
import injectSheet from "../../hocs/injectSheet/injectSheet";
import CampoBuscarLeads from "../../containers/CampoBuscarLeads/CampoBuscarLeads";
import IntegracaoLigacao from "./IntegracaoLigacao";
import MenuInformacoesConta from "../../containers/MenuInformacoesConta/MenuInformacoesConta";
import IntegracaoWhatsApp from "../../../pages/whatsapp";
import ModalHistoricoWhatsApp from "../../../pages/whatsapp/HistoryMessages";
import MaisButton from "./MaisButton";
import Guia from "./Guia";
import { configurarApiCentralEmails } from "../../../store/reducers/emails/services";
import AtividadesNavbar from "./AtividadesNavbar";
import { useTipoPlanoSpotter, useUsuario } from "../../../hooks";
import logoSpotter from "../../../_common/icones/logo-spotter.png";
import logoSpotterTransparente from "../../../_common/icones/logo-spotter-transparente.png";
import { selectUsuarioEstaLogado } from "../../../store/reducers/usuario/selectors";
import ExactClub from "./ExactClub";
import TagSpotterBasic from "./TagSpotterBasic";

const styles = theme => ({
  navbar: {
    position: "fixed",
    zIndex: 4,
    paddingLeft: "47%",
    width: "100%",
    height: 57,
    backgroundColor: theme.palette.primary2Color,
  },
  navbarComLink: {
    "& a[href='https://www.exactsales.com.br']": {
      position: "absolute",
      right: 0,
      zIndex: 4,
      marginRight: 8,
    },
    "& a[href='/']": {
      height: "34px",
      width: "210px",
      float: "left !important",
    },
    "& a[href='/'] > img": { maxHeight: "42px" },
    position: "fixed",
    zIndex: 5,
    top: 0,
    width: "100%",
    height: "57px",
    backgroundColor: theme.palette.primary2Color,
  },
  menuUsuario: {
    marginLeft: "auto",
    paddingRight: 16,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: 8,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function Navbar({ classes, isRouterPublic, urlLogoEmpresa }) {
  const { isPreVendedor, isGerente, featureToggles } = useUsuario();
  const [navBarVisivel, setNavBarVisivel] = useState(true);
  const usuarioEstaLogado = useSelector(selectUsuarioEstaLogado);
  const isSpotterFull = useTipoPlanoSpotter();
  const mostrarExactClub = isSpotterFull && (isGerente || featureToggles.menuExactClub);

  useEffect(() => {
    if (isPreVendedor && !isRouterPublic) configurarApiCentralEmails();
  }, []);

  useEffect(
    () => {
      if (usuarioEstaLogado !== undefined && !usuarioEstaLogado) {
        setNavBarVisivel(false);
      }
    },
    [usuarioEstaLogado]
  );

  return isRouterPublic ? (
    ObterNavBarPublic(urlLogoEmpresa, classes)
  ) : (
    <>
      {navBarVisivel && (
        <Flex center className={classes.navbar}>
          <MaisButton className={classes.botaoMais} />
          <CampoBuscarLeads />
          <Flex className={classes.menuUsuario}>
            {mostrarExactClub && <ExactClub />}
            {!isSpotterFull && <TagSpotterBasic />}
            <AtividadesNavbar />
            <IntegracaoLigacao />
            <IntegracaoWhatsApp />
            <Guia />
            <MenuInformacoesConta />
            <ModalHistoricoWhatsApp />
          </Flex>
        </Flex>
      )}
    </>
  );
}

Navbar.propTypes = {
  classes: PropTypes.object,
  isRouterPublic: PropTypes.bool,
  urlLogoEmpresa: PropTypes.string,
};

function ObterNavBarPublic(urlLogoEmpresa, classes) {
  return urlLogoEmpresa !== undefined && urlLogoEmpresa !== "" ? (
    <DrawerHeader className={classes.navbarComLink}>
      <a href="/" className="navbar-brand">
        <img src={urlLogoEmpresa} alt="img_logo" />
      </a>

      <a href="https://www.exactsales.com.br">
        <img src={logoSpotterTransparente} alt="logo_exact" />
      </a>
    </DrawerHeader>
  ) : (
    <DrawerHeader className={classes.navbar} style={{ position: "sticky", top: "0" }}>
      <a href="https://www.exactsales.com.br">
        <img src={logoSpotter} alt="logo_exact" />
      </a>
    </DrawerHeader>
  );
}

export default injectSheet(styles)(Navbar);
