import { types } from "./actions";
import { initialState, DEFAULT_GROUP, DEFAULT_RULE } from "./initialState";
import { map, find } from "../../../utils/fp";
import { DEFAULT_CONFIG, NO_DATA } from "../constants";

const deepCopy = obj => JSON.parse(JSON.stringify(obj));

export default function reducer(state = initialState(), action) {
  switch (action.type) {
    case types.SET_FIELDS: {
      return {
        ...state,
        fieldsOptions: action.payload,
      };
    }
    case types.SET_RESPONSE: {
      return {
        ...state,
        fieldsResponses: action.payload,
      };
    }
    case types.MERGE_OPTIONS_INTO_FIELD: {
      const { options, fieldValue } = action.payload;
      const { fieldsOptions } = state;
      const updateField = field => (field.id === fieldValue ? { ...field, options } : field);
      return {
        ...state,
        fieldsOptions: map(updateField, fieldsOptions),
      };
    }
    case types.MERGE_FIELD_INTO_RESPONSE: {
      const { x } = action.payload;
      return {
        ...state,
        fieldsResponses: x,
      };
    }
    case types.UPDATE_QUERY: {
      return {
        ...state,
        query: action.payload,
      };
    }
    case types.CHANGE_GLOBAL_COMBINATOR: {
      return {
        ...state,
        query: {
          ...state.query,
          globalCombinator: action.payload,
        },
      };
    }
    case types.ADD_GROUP: {
      const { groups } = state.query;
      return {
        ...state,
        query: {
          ...state.query,
          groups: [...groups, DEFAULT_GROUP()],
        },
      };
    }
    case types.REMOVE_GROUP: {
      const groupIndex = action.payload;
      const { groups } = state.query;
      return {
        ...state,
        query: {
          ...state.query,
          groups: groups.filter((_, index) => index !== groupIndex),
        },
      };
    }
    case types.REMOVE_RULE: {
      const { groupIndex, ruleIndex } = action.payload;
      const groups = [...state.query.groups];
      const targetGroup = deepCopy(groups[groupIndex]);
      const regras = targetGroup.rules.filter((_, index) => index !== ruleIndex);

      const updatedGroup = {
        ...targetGroup,
        rules: regras,
      };

      groups[groupIndex] = updatedGroup;

      return {
        ...state,
        query: {
          ...state.query,
          groups,
        },
      };
    }
    case types.ADD_RULE: {
      const groups = [...state.query.groups];
      const groupIndex = action.payload;
      const targetGroup = deepCopy(groups[groupIndex]);

      const updatedGroup = {
        ...targetGroup,
        rules: [...targetGroup.rules, DEFAULT_RULE()],
      };

      groups[groupIndex] = updatedGroup;

      return {
        ...state,
        query: {
          ...state.query,
          groups,
        },
      };
    }
    case types.CHANGE_GROUP_COMBINATOR: {
      const { groupIndex, value } = action.payload;
      const groups = [...state.query.groups];
      groups[groupIndex] = {
        ...groups[groupIndex],
        combinator: value,
      };

      return {
        ...state,
        query: {
          ...state.query,
          groups,
        },
      };
    }
    case types.CHANGE_RULE_FIELD: {
      const { groupIndex, ruleIndex, fieldValue } = action.payload;
      const groups = [...state.query.groups];

      if (ruleIndex === undefined) {
        groups[groupIndex].rules = [{}];
      }

      const resolvedRuleIndex = ruleIndex || 0;
      groups[groupIndex].rules[resolvedRuleIndex].field = fieldValue;

      const { fieldsOptions } = state;
      const { options } = find(field => field.id === fieldValue, fieldsOptions);
      groups[groupIndex].rules[resolvedRuleIndex].value = options.length ? options[0].value : NO_DATA;
      groups[groupIndex].rules[resolvedRuleIndex].response = [];

      return {
        ...state,
        targetFieldValue: fieldValue,
        fieldsResponses: [],
        query: {
          ...state.query,
          groups,
        },
      };
    }
    case types.CHANGE_RULE_RESPONSE: {
      const { groupIndex, ruleIndex, response } = action.payload;
      const groups = [...state.query.groups];

      groups[groupIndex] = {
        ...groups[groupIndex],
        rules: [...groups[groupIndex].rules],
      };

      groups[groupIndex].rules[ruleIndex] = {
        ...groups[groupIndex].rules[ruleIndex],
        response,
      };

      return {
        ...state,
        query: {
          ...state.query,
          groups,
        },
      };
    }
    case types.CHANGE_RULE_VALUE: {
      const { groupIndex, ruleIndex, value } = action.payload;
      const groups = [...state.query.groups];

      groups[groupIndex] = {
        ...groups[groupIndex],
        rules: [...groups[groupIndex].rules],
      };

      groups[groupIndex].rules[ruleIndex] = {
        ...groups[groupIndex].rules[ruleIndex],
        value,
      };

      return {
        ...state,
        query: {
          ...state.query,
          groups,
        },
      };
    }
    case types.SET_QUERY_CONFIG: {
      const newConfig = action.payload || {};

      const config = {
        MAX_GROUPS: newConfig.MAX_GROUPS || DEFAULT_CONFIG().MAX_GROUPS,
        MAX_RULES: newConfig.MAX_RULES || DEFAULT_CONFIG().MAX_RULES,
      };

      return {
        ...state,
        config: {
          ...state.config,
          ...config,
        },
      };
    }
    case types.RESET: {
      return initialState();
    }
    default:
      return state;
  }
}
