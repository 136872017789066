import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import CommunicationPhone from "@material-ui/icons/PermPhoneMsg";

import { Flex, Tipografia, Divider } from "../..";
import injectSheet from "../../hocs/injectSheet/injectSheet";

const styles = theme => ({
  communicationPhone: { width: 24, height: 24, color: theme.palette.icons.lightActiveIcon },
  flexCreditos: { padding: "0px 16px 0px 8px" },
  divider: { height: "60%", backgroundColor: theme.palette.icons.lightActiveIcon },
});

const getSaldoLigacao = async accessToken => {
  const URL = "https://exactsales.totalvoice.com.br/saldo";
  const data = await fetch(URL, { headers: { "Access-Token": accessToken } });
  const resp = await data.json();

  if (resp) {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });

    return formatter.format(resp.dados.saldo);
    // this.setState({ possuiIntegracaoTotalVoice: true, saldo: saldoFormatado });
  }
  return null;
};

const SaldoTotalVoice = ({ token, theme, classes }) => {
  const [saldo, setSaldo] = useState();
  useEffect(
    () => {
      if (token) getSaldoLigacao(token).then(setSaldo);
    },
    [token]
  );

  if (!saldo) return null;

  return (
    <Flex alignItems="center">
      <CommunicationPhone color={theme.palette.icons.lightActiveIcon} className={classes.communicationPhone} />

      <Flex flexDirection="column" alignItems="center" className={classes.flexCreditos}>
        <Tipografia cor="lightSecondaryText" tipo="notaLegendas">
          Créditos
        </Tipografia>
        <Tipografia cor="lightSecondaryText">{saldo}</Tipografia>
      </Flex>
      <Divider className={classes.divider} />
    </Flex>
  );
};

SaldoTotalVoice.propTypes = {
  token: PropTypes.string,
  theme: PropTypes.object,
  classes: PropTypes.object,
};
export default injectSheet(styles)(SaldoTotalVoice);
