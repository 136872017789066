import React from "react";
import PropTypes from "prop-types";
import { Typography, withStyles } from "@material-ui/core";
import cn from "classnames";

import { StarRating } from ".";

StarRatingField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClickRating: PropTypes.func,
  hasError: PropTypes.bool,
};

StarRatingField.defaultProps = {
  hasError: false,
};

function StarRatingField({ className, label, hasError, onClickRating, classes, ...props }) {
  return (
    <div className={cn(classes.root, className)}>
      <Typography className={classes.tipografia} color={hasError ? "error" : "textPrimary"}>
        {label}:{" "}
      </Typography>
      <StarRating onClick={onClickRating} {...props} />
    </div>
  );
}

export default withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tipografia: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",
    marginRight: 8,
  },
})(StarRatingField);
