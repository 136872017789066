import React from "react";
import PropTypes from "prop-types";

import Close from "@material-ui/icons/Close";
import { Dialog, IconButton } from "@material-ui/core/";
import injectSheet from "../../hocs/injectSheet/injectSheet";
import Flex from "../Flex";

const styles = theme => ({
  title: {
    height: 56,
    paddingLeft: 24,
    paddingRight: 8,
    marginBottom: 16,
    backgroundColor: theme.palette.primary2Color,
    color: `${theme.palette.text.lightPrimaryText} !important`,
  },
  closeButton: {
    marginLeft: "auto",
  },
  tituloModal: {
    fontSize: 22,
    fontWeight: 400,
  },
});

const NovaModal = ({ title, children, classes, classesDialog, mostrarBotaoFechar, ...props }) => (
  <Dialog {...props} classes={classesDialog}>
    <Flex alignItems="center" className={classes.title}>
      <span className={classes.tituloModal}>{title}</span>
      {mostrarBotaoFechar && (
        <IconButton color="inherit" classes={{ root: classes.closeButton }} onClick={props.onClose}>
          <Close />
        </IconButton>
      )}
    </Flex>

    {children}
  </Dialog>
);

NovaModal.propTypes = {
  /** Título da modal. */
  title: PropTypes.string.isRequired,
  /** Conteúdo da modal. */
  children: PropTypes.node.isRequired,
  /** @ignore */
  classes: PropTypes.object,
  /** Classe a serem passadas para o Dialog */
  classesDialog: PropTypes.object,
  /** Propriedade usada para mostrar o botão fechar no modal. */
  mostrarBotaoFechar: PropTypes.bool,
  /** Função usada para fechar a modal. */
  onClose: PropTypes.func,
};

export default injectSheet(styles)(NovaModal);
