import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { withStyles } from "@material-ui/core";
import { noop } from "lodash";

StarRating.propTypes = {
  numberOfStars: PropTypes.number,
  rating: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
};

StarRating.defaultProps = {
  numberOfStars: 5,
  rating: 0,
  disabled: false,
  readOnly: false,
};

function StarRating({ numberOfStars, rating, disabled, onClick, readOnly, classes }) {
  if (!Number.isInteger(rating) || rating < 0 || !Number.isInteger(numberOfStars) || numberOfStars <= 0) {
    return null;
  }

  const starsArray = [...new Array(numberOfStars)].map((_, i) => i + 1);

  const isDisabled = !readOnly && (disabled || !onClick || typeof onClick !== "function");

  const onClickStar = useCallback(val => (disabled ? noop() : onClick(val)), [disabled]);

  return (
    <div className={cn(classes.root, { [classes.rootDisabled]: isDisabled, [classes.rootReadOnly]: readOnly })}>
      {starsArray.map(val => {
        if (val <= rating) {
          return (
            <StarIcon
              color="primary"
              className={classes.star}
              onClick={() => onClickStar(val)}
              key={val}
              data-testid="starIconFilled"
            />
          );
        }

        return (
          <StarBorderIcon
            color="primary"
            className={classes.star}
            onClick={() => onClickStar(val)}
            key={val}
            data-testid="starIconEmpty"
          />
        );
      })}
    </div>
  );
}

export default withStyles({
  root: {
    display: "flex",
  },
  rootDisabled: {
    opacity: 0.5,
    cursor: "initial",
    pointerEvents: "none",
  },
  rootReadOnly: {
    cursor: "initial",
    pointerEvents: "none",
  },
  star: {
    cursor: "pointer",
  },
})(StarRating);
