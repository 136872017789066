import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Paper, MenuItem, Checkbox } from "material-ui";
import { Flex, Divider, Tipografia } from "../..";

import injectSheet from "../../hocs/injectSheet/injectSheet";
import MenuItemCriarNovo from "../../../_common/components/MenuItemCriarNovo/MenuItemCriarNovo";

export const styles = {
  itemList: {
    minWidth: ({ minWidth }) => minWidth,
    maxHeight: "50vh",
    overflow: "auto",
    zIndex: 3,
  },
  menuItem: {
    display: "flex !important",
    alignItems: "center",
    fontSize: "14px",
    maxWidth: 800,
  },
  conatinerPesquisar: {
    height: 40,
  },
  inputPesquisar: {
    width: "100%",
    paddingLeft: 16,
    border: "none",
    outline: "none",
  },
  divider: {
    width: "100% !important",
  },
  semItens: {
    height: 60,
  },
};

const MultiSelectItemsList = ({
  items,
  floatingLabelText,
  searchTerm,
  handleOnChangeSearchTerm,
  allChecked,
  getItemProps,
  substituirPlaceholder,
  urlCriarNovo,
  callbackCriarNovo,
  classes,
}) => {
  const intl = useIntl();

  const placeholder = !substituirPlaceholder
    ? floatingLabelText.toLowerCase()
    : intl.formatMessage(
        { defaultMessage: "Pesquisar por {fieldName}..." },
        {
          fieldName: floatingLabelText.toLowerCase(),
        }
      );

  return (
    <Paper zDepth={3} className={classes.itemList}>
      <Flex className={classes.conatinerPesquisar}>
        <input
          type="text"
          id="searchTerm"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleOnChangeSearchTerm}
          className={classes.inputPesquisar}
          autoComplete="off"
        />
      </Flex>

      <Divider className={classes.divider} />
      {items.length > 0 && (
        <MenuItem
          key="checkAll"
          primaryText={
            <Checkbox
              label={intl.formatMessage({ defaultMessage: "Todos" })}
              checked={allChecked}
              style={{ fontSize: "14px" }}
            />
          }
          className={classes.menuItem}
          {...getItemProps({
            item: {
              id: "checkAll",
              descricao: intl.formatMessage({ defaultMessage: "Todos" }),
              checked: allChecked,
            },
          })}
        />
      )}

      {items.map(item => (
        <MenuItem
          key={item.id}
          primaryText={<Checkbox label={item.descricao} checked={item.checked} style={{ fontSize: "14px" }} />}
          className={classes.menuItem}
          {...getItemProps({ item })}
        />
      ))}

      {!items.length && (
        <Flex justifyContent="center" alignItems="center" className={classes.semItens}>
          <Tipografia cor="darkSecondaryText">{intl.formatMessage({ defaultMessage: "Sem resultado." })}</Tipografia>
        </Flex>
      )}
      {urlCriarNovo && (
        <div style={{ paddingBottom: 10, height: 50 }}>
          <MenuItemCriarNovo url={urlCriarNovo} intl={intl} callBack={callbackCriarNovo} />
        </div>
      )}
    </Paper>
  );
};

MultiSelectItemsList.propTypes = {
  /** Items do componente. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      descricao: PropTypes.string.isRequired,
      checked: PropTypes.bool,
    })
  ).isRequired,
  /** Label do input. */
  floatingLabelText: PropTypes.string,
  /** Texto utilizado como busca na lista dos itens */
  searchTerm: PropTypes.string,
  /**
   * Função chamada para modificar o texto da busca na lista dos itens.
   * @param {string} value
   */
  handleOnChangeSearchTerm: PropTypes.func,
  allChecked: PropTypes.bool,
  /** Largura do componente (em 'px') */
  minWidth: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
  /** @ignore */
  getItemProps: PropTypes.func.isRequired,
  /** @ignore */
  substituirPlaceholder: PropTypes.bool,
  urlCriarNovo: PropTypes.string,
  urlBuscar: PropTypes.string,
  callbackCriarNovo: PropTypes.func,
  classes: PropTypes.object,
};

export default injectSheet(styles)(MultiSelectItemsList);
