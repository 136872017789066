import React from "react";
import PropTypes from "prop-types";

import { Switch } from "@material-ui/core";

const SwitchControl = ({ checked, valor, onChange, cor }) => (
  <Switch checked={checked} onChange={onChange} color={cor} value={valor} />
);

SwitchControl.propTypes = {
  checked: PropTypes.bool.isRequired,
  valor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Função que será executada ao clicar no componente. onChange(event: object, checked: boolean) */
  onChange: PropTypes.func,
  /** Cor do componente. Se não for declarada, o padrão é "default" */
  cor: PropTypes.oneOf(["primary"]),
};

export default SwitchControl;
