/* eslint-disable camelcase */
import React, { useState } from "react";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import AddCircle from "@material-ui/icons/AddCircleOutline";
import Business from "@material-ui/icons/Business";
import Work from "@material-ui/icons/Work";
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Dialog } from "@material-ui/core";
import { useIntl } from "react-intl";

import { ajaxBlackout, createSnackbar } from "../..";
import OrganizacaoCadastrarModalForm from "../../../components/Organizacao/Cadastrar/ModalForm";
import ModalCadastrar from "../../../pages/lead/components/cadastrar/ModalCadastrar";
import ModalAtividadeNewCont from "../../../pages/atividade/components/ModalAtividadeNewCont";
import { useUsuario } from "../../../hooks";
import { IdsInlineManual } from "../../utils/constants/inlineManual";

function MaisButton() {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOrganizacaoIsOpen, setModalOrganizacaoIsOpen] = useState(false);
  const [modalLeadIsOpen, setModalLeadIsOpen] = useState(false);
  const [abrirModalCriarAtividade, setAbrirModalCriarAtividade] = useState(false);

  const { isGerente, id: usuarioId } = useUsuario();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickOrganizacao = () => {
    setModalOrganizacaoIsOpen(true);
    handleClose();
  };

  const onClickAtividade = () => {
    setAbrirModalCriarAtividade(true);
    handleClose();
  };

  const onClickLead = () => {
    setModalLeadIsOpen(true);
    handleClose();
  };

  return (
    <>
      <IconButton id="mais-button" onClick={handleClick} style={{ right: "240px" }}>
        <AddCircle style={{ color: "#fff" }} />
      </IconButton>
      <Menu
        id="mais-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem id={IdsInlineManual.LEAD_OPORTUNIDADE_NAVBAR} onClick={onClickLead}>
          <ListItemIcon>
            <Work />
          </ListItemIcon>
          <ListItemText inset primary={intl.formatMessage({ defaultMessage: "Lead/Oportunidade" })} />
        </MenuItem>
        <MenuItem onClick={onClickOrganizacao}>
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          <ListItemText inset primary={intl.formatMessage({ defaultMessage: "Empresa/Organização" })} />
        </MenuItem>
        <MenuItem onClick={onClickAtividade}>
          <ListItemIcon>
            <AssignmentTurnedIn />
          </ListItemIcon>
          <ListItemText inset primary={intl.formatMessage({ defaultMessage: "Atividade" })} />
        </MenuItem>
      </Menu>
      <Dialog open={modalLeadIsOpen}>
        <ModalCadastrar
          onConfirm={newLeadId => {
            document.dispatchEvent(new Event("lead/novo-cadastro"));
            document.dispatchEvent(new Event("pipeline/reload"));
            window.open(`/spotter/detalhes-lead/${newLeadId}`);
            ajaxBlackout.hide();
            setModalLeadIsOpen(false);
            createSnackbar(intl.formatMessage({ defaultMessage: "Lead cadastrado com sucesso." }));
          }}
          onCancel={() => setModalLeadIsOpen(false)}
          idBtnCadastrar={IdsInlineManual.BTN_CADASTRAR_LEAD}
        />
      </Dialog>
      <Dialog open={modalOrganizacaoIsOpen}>
        <OrganizacaoCadastrarModalForm
          onConfirm={newOrgId => {
            document.dispatchEvent(new Event("organizacao/novo-cadastro"));
            window.open(`/spotter/organizacao/${newOrgId}`);
            ajaxBlackout.hide();
            setModalOrganizacaoIsOpen(false);
            createSnackbar(intl.formatMessage({ defaultMessage: "Organização cadastrada com sucesso." }));
          }}
          onCancel={() => setModalOrganizacaoIsOpen(false)}
        />
      </Dialog>

      <ModalAtividadeNewCont
        open={abrirModalCriarAtividade}
        onClose={() => setAbrirModalCriarAtividade(false)}
        isGerente={isGerente}
        preVendedorId={!isGerente ? usuarioId : null}
      />
    </>
  );
}

export default MaisButton;
