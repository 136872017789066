import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "recompose";
import { withTheme } from "react-jss";
import { Dialog, DialogTitle, DialogActions, DialogContent, IconButton } from "@material-ui/core";
import { NavigationClose } from "material-ui/svg-icons";
import injectSheet from "../../hocs/injectSheet/injectSheet";
import ConnectedIntlProvider from "../../containers/ConnectedIntlProvider";

const tamanhoPadraoDaModal = "40%";

const styles = theme => ({
  title: {
    padding: "12px 24px !important",
    backgroundColor: theme.palette.primary2Color,
    color: `white !important`,
  },
  root: {
    width: ({ tamanho }) => `${tamanho} !important`,
    maxWidth: "1366px !important",
    minWidth: ({ tamanho }) => (tamanho !== tamanhoPadraoDaModal ? tamanho : `768px`),
    height: "650px",
  },
  closeButton: {
    position: "absolute !important",
    right: 8,
    top: 4,
  },
  contentRoot: {
    paddingBottom: 0,
  },
});

const Modal = ({
  title,
  theme,
  children,
  classes,
  tamanho = tamanhoPadraoDaModal,
  contentClassName,
  mostraBotaoFechar,
  onRequestClose,
  onClose,
  actions,
  PaperProps = {},
  DialogActionsProps = {},
  ...props
}) => (
  <Dialog {...props} PaperProps={PaperProps} onClose={onClose}>
    <DialogTitle className={classes.title}>
      <span style={{ color: "white" }}>{title}</span>
      {mostraBotaoFechar && (
        <IconButton className={classes.closeButton} onClick={onRequestClose || onClose}>
          <NavigationClose color={theme.palette.icons.lightFocusedIcon} />
        </IconButton>
      )}
    </DialogTitle>

    <DialogContent
      classes={{ root: classes.contentRoot }}
      className={classNames(contentClassName, { [classes.modalContentClass]: tamanho })}
    >
      <ConnectedIntlProvider>{children}</ConnectedIntlProvider>
    </DialogContent>

    {actions && <DialogActions {...DialogActionsProps}>{actions}</DialogActions>}
  </Dialog>
);

Modal.propTypes = {
  /** Conteúdo da modal. */
  children: PropTypes.node.isRequired,

  /** @ignore */
  classes: PropTypes.object,

  /** Propriedade que define o tamanho do componente. */
  tamanho: PropTypes.string,

  /** Nome da classe css a ser aplicada no componente. <br />
   *  Se utilizar o `injectsheet`: usar `{classes.nome_da_classe}` */
  contentClassName: PropTypes.string,

  /** Propriedade usada para mostrar o botão fechar no modal. */
  mostraBotaoFechar: PropTypes.bool,

  /** Função usada para fechar a modal. */
  onRequestClose: PropTypes.func,
  onClose: PropTypes.func,

  /** @ignore */
  theme: PropTypes.object.isRequired,

  title: PropTypes.node,
  actions: PropTypes.node,
  PaperProps: PropTypes.object,
  DialogActionsProps: PropTypes.object,
};

const enhance = compose(
  injectSheet(styles),
  withTheme
);
export default enhance(Modal);
