/* eslint-disable camelcase */
import React, { useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import { Tooltip } from "react-tippy";
import { connect } from "react-redux";

import { Flex, createSnackbar, ExactWebPhone } from "../..";
import createDialogConfirm from "../../utils/dialogConfirm/createDialogConfirm";
import { useUsuario } from "../../../hooks";

WebPhoneButton.propTypes = {
  isPreVendedor: PropTypes.bool,
  url: PropTypes.string,
  parceiroWebPhone: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

function WebPhoneButton({ isPreVendedor, url, parceiroWebPhone, onClick, ...props }) {
  const intl = useIntl();
  const renderIcon = useCallback(
    (label, cb) => (
      <Flex alignItems="center">
        <Tooltip title={label}>
          <IconButton onClick={cb}>
            <PhoneIcon style={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>
      </Flex>
    ),
    []
  );

  const { id, empresaClienteId, jwt, tipoGravacaoWebphone } = useUsuario();

  useEffect(
    () => {
      if (!url) return;

      ExactWebPhone().initState({
        ...props,
        iFrameSrc: url,
        org_id: empresaClienteId,
        user_id: id,
        token: jwt,
        parceiroWebPhone,
        tipoGravacaoWebphone,
        onError: e =>
          createSnackbar(
            (e.responseJSON && e.responseJSON.ExceptionMessage) ||
              intl.formatMessage({ defaultMessage: "Ocorreu um erro" })
          ),
      });
      if (isPreVendedor) ExactWebPhone().autoOpen(url);
    },
    [url]
  );

  if (!url)
    return renderIcon(intl.formatMessage({ defaultMessage: "Webphone" }), () =>
      window.open("https://exactsal.es/lp-wp-spotter-v2", "_blank")
    );

  return renderIcon(intl.formatMessage({ defaultMessage: "Abrir Webphone" }), () => {
    try {
      onClick();
      ExactWebPhone().open();
    } catch (e) {
      createDialogConfirm({
        text: e.message,
        callback: s => s && ExactWebPhone().forceOpen(url),
      });
    }
  });
}

const mapStateToProps = state => ({
  isPreVendedor: state.usuario.isPreVendedor,
});

export default connect(mapStateToProps)(WebPhoneButton);
