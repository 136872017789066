import React from "react";
import PropTypes from "prop-types";
import injectSheet, { withTheme } from "react-jss";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import { Tipografia, Flex } from "../..";

const style = theme => ({
  tagNovo: {
    margin: [0, 5],
    padding: [0, 10],
    borderRadius: 10,
    backgroundColor: theme.palette.accent1Color,
    width: "max-content",
    display: "inline-flex",
  },
});

const TagNovo = ({ classes }) => (
  <Flex center className={classes.tagNovo}>
    <Tipografia style={{ color: "white" }} tipo="notaLegendas">
      <FormattedMessage defaultMessage="novo" />
    </Tipografia>
  </Flex>
);

TagNovo.propTypes = {
  /** @ignore */
  classes: PropTypes.object,
};

const enhance = compose(
  withTheme,
  injectSheet(style)
);
export default enhance(TagNovo);
