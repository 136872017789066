import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import Truncate from "../Truncate";

TooltipTruncateV0.propTypes = {
  numberOfLines: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  TooltipProps: PropTypes.object,
};

/**
 * Usa o componente antigo Tipografia que mantém os estilos
 * consistentes em componentes que usem Material-UI v0
 */
function TooltipTruncateV0({ children, numberOfLines = 1, TooltipProps, ...props }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipProps = {
    title: showTooltip ? <div style={{ wordWrap: "break-word" }}>{children}</div> : "",
    style: { display: "inline-block", overflow: "auto", maxWidth: "100%" },
    ...TooltipProps,
  };

  return (
    <Tooltip {...tooltipProps}>
      <Truncate {...props} numberOfLines={numberOfLines} onTruncate={setShowTooltip}>
        {children}
      </Truncate>
    </Tooltip>
  );
}

export default TooltipTruncateV0;
