import React from "react";
import { Select, withStyles } from "@material-ui/core";
import { menuScrollableStyle } from "./style";

/**
 * Select do Material-UI com scroll horizontal
 */
function SelectScrollableMUI({ classes, ...props }) {
  return (
    <Select
      MenuProps={{
        classes: {
          paper: classes.menu,
        },
      }}
      {...props}
    />
  );
}

export default withStyles({ menu: menuScrollableStyle })(SelectScrollableMUI);
