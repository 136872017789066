import React from "react";
import PropTypes from "prop-types";
import TruncateMarkup from "react-truncate-markup";

import { Tipografia } from "../..";

const Truncate = ({ numberOfLines, url, isExternalUrl, children, color, type, onTruncate, ...props }) => (
  <Tipografia
    {...(isExternalUrl ? { urlExterna: isExternalUrl } : {})}
    {...(url ? { ...{ url } } : {})}
    {...(color ? { cor: color } : {})}
    {...(type ? { tipo: type } : {})}
    {...props}
  >
    <TruncateMarkup lines={numberOfLines} ellipsis={<span>...</span>} onTruncate={onTruncate}>
      <div style={{ wordBreak: "break-word" }}>{children}</div>
    </TruncateMarkup>
  </Tipografia>
);

Truncate.propTypes = {
  /** It define number of lines will be showed before truncate  */
  numberOfLines: PropTypes.number,

  /** Address to navigation. If there is any, truncate content will be a link.  */
  url: PropTypes.string,

  /** Sets as external URL. If true, component <a> will be used. */
  isExternalUrl: PropTypes.bool,

  /** Content will be showed as truncated */
  children: PropTypes.node.isRequired,

  /** Color of content. The colors will be same of Tipografia component color list. */
  color: PropTypes.oneOf([
    "darkPrimaryText",
    "darkSecondaryText",
    "darkHintText",
    "darkDividers",
    "lightPrimaryText",
    "lightSecondaryText",
    "lightHintText",
    "lightDividers",
  ]),

  /** It define font size will be used */
  type: PropTypes.oneOf([
    "titulo1",
    "titulo2",
    "tituloCard",
    "navbar",
    "subtituloConteudo",
    "textoSidebar",
    "cardIndicator",
    "botoes",
    "notaLegendas",
    "tooltip",
  ]),
  onTruncate: PropTypes.func,
};

export default Truncate;
