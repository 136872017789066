import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip, Typography } from "@material-ui/core";
import TruncateMarkup from "react-truncate-markup";

TooltipTruncate.propTypes = {
  /**
   * Utiliza o children para renderizar o texto que ser� truncado. Automaticamente tenta usar
   * o children tamb�m como tooltip, caso `title` n�o seja fornecido.
   */
  children: PropTypes.node,
  numberOfLines: PropTypes.number,
  /** Utilizado na tooltip. Caso n�o seja fornecido, usar� `children`. */
  title: PropTypes.node,
  TooltipProps: PropTypes.object,
  TypographyProps: PropTypes.object,
};

TooltipTruncate.defaultProps = {
  title: "",
  TooltipProps: {},
  TypographyProps: {},
};

function TooltipTruncate({ children, numberOfLines = 1, TooltipProps, TypographyProps }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipProps = {
    title: showTooltip ? <div style={{ wordWrap: "break-word" }}>{children}</div> : "",
    style: { display: "inline-block", maxWidth: "100%" },
    ...TooltipProps,
  };

  return (
    <Tooltip {...tooltipProps}>
      <Typography {...TypographyProps}>
        <TruncateMarkup lines={numberOfLines} ellipsis={<span>...</span>} onTruncate={setShowTooltip}>
          <span style={{ wordBreak: "break-word", display: "block" }}>{children}</span>
        </TruncateMarkup>
      </Typography>
    </Tooltip>
  );
}

export default TooltipTruncate;
