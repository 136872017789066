import React, { useEffect, useState, useCallback } from "react";
// import PropTypes from "prop-types";
import axios from "axios";

import WebPhoneButton from "./WebPhoneButton";
import { ExactWebPhone } from "../../../_common";
import { PHONE_OPERATOR } from "../../../_common/constantes";
import SaldoTotalVoice from "../SaldoTotalVoice/SaldoTotalVoice";
import { useEvent } from "../../../hooks";

export default function IntegracaoLigacao() {
  const [plataforma, setPlataforma] = useState(PHONE_OPERATOR.NONE);
  const [propsPlataforma, setProps] = useState({});
  const [loading, setLoading] = useState(true);
  const getCredencial = useCallback(async () => {
    try {
      const URL = "/api/pipeline/credencialLigacao/buscar";
      const { operadora, ...props } = await axios.get(URL).then(({ data }) => data);
      setPlataforma(operadora);
      setProps(props);
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCredencial();
  }, []);

  useEvent("integracaoLigacao/reload", () => {
    getCredencial();
    ExactWebPhone().reloadWebphoneData();
  });

  if (loading) return null;

  return (
    <>
      <WebPhoneButton {...propsPlataforma} onClick={getCredencial} />
      {plataforma === PHONE_OPERATOR.TOTAL_VOICE && <SaldoTotalVoice {...propsPlataforma} />}
    </>
  );
}
