import React, { useState, memo, useRef } from "react";
import PropTypes from "prop-types";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { Popover, MenuItem } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import useSWR from "swr";

import Truncate from "../Truncate";
import { Container, SearchContainer, Placeholder, ListItens, CloseButton } from "../SearchSelectDebounce/styles";
import { useDebounce } from "../../../hooks";

function SearchSelectDebounce({ url, value, label, labelSearch, attribute, width, onChange, className }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [term, setTerm] = useState("");
  const debouncedTerm = useDebounce(term);
  const inputRef = useRef(null);

  const isFuncUrl = typeof url === "function";

  const { data: itens, isValidating: loading } = useSWR(
    () => {
      if (isFuncUrl) {
        return debouncedTerm ? url(debouncedTerm) : null;
      }
      return url;
    },
    {
      fallbackData: [],
      revalidateOnMount: true,
    }
  );

  function handleSelect(item) {
    inputRef.current.value = "";
    setAnchorEl(null);
    onChange(item);
  }

  return (
    <>
      <Container
        id="search-select-container"
        className={className}
        style={{ opacity: loading ? 0.6 : 1, maxWidth: width }}
        onClick={e => !loading && setAnchorEl(e.currentTarget)}
      >
        <Truncate lines={1} color="darkPrimaryText" type="textoSidebar">
          {value && value[attribute] ? <span>{value[attribute]}</span> : <Placeholder> {label}</Placeholder>}
        </Truncate>
        {loading ? <CircularProgress size={23} color="primary" /> : <ArrowDropDown />}
      </Container>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => {
          setAnchorEl(null);
          setTerm("");
        }}
      >
        <SearchContainer style={{ maxWidth: width, width }}>
          <SearchIcon style={{ left: 5 }} />
          <input ref={inputRef} onChange={e => setTerm(e.target.value)} placeholder={labelSearch} type="text" />
          {value && value[attribute] && <CloseButton onClick={() => handleSelect(null)} />}
        </SearchContainer>
        <ListItens>
          {itens.map((item, index) => (
            <MenuItem
              key={item.id || index}
              title={item[attribute]}
              onClick={() => {
                handleSelect(item);
              }}
            >
              <Truncate lines={1} color="darkPrimaryText" type="textoSidebar">
                {item[attribute]}
              </Truncate>
            </MenuItem>
          ))}
        </ListItens>
      </Popover>
    </>
  );
}
SearchSelectDebounce.propTypes = {
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.object,
  labelSearch: PropTypes.string,
  label: PropTypes.string,
  attribute: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default memo(SearchSelectDebounce);
