import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import { Container, TableHeader, Head, TableBody } from "./styles";

function TableList({ headers, children, onChangeOrder }) {
  const intl = useIntl();
  const [order, setOrder] = useState(null);

  function handleChangeOrder(head) {
    if (head.sortable) {
      let newOrder = null;
      if (head.id === order?.id) {
        newOrder = {
          id: head.id,
          direction: order?.direction === "ASC" ? "DESC" : "ASC",
        };
      } else {
        newOrder = {
          id: head.id,
          direction: "ASC",
        };
      }
      setOrder(newOrder);
      onChangeOrder(newOrder);
    }
  }

  return (
    <Container>
      <TableHeader>
        {headers.map(head => (
          <Head
            key={head.id}
            justify={head.justify}
            sortable={head.sortable}
            size={head.size}
            onClick={() => handleChangeOrder(head)}
          >
            {head.render
              ? head.render()
              : (typeof head.text === "string" ? head.text : intl.formatMessage(head.text)) ?? ""}
            {order?.id === head.id && <>{order.direction === "ASC" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</>}
          </Head>
        ))}
      </TableHeader>
      <TableBody headers={headers}>{children}</TableBody>
    </Container>
  );
}

TableList.defaultProps = {
  onChangeOrder: () => {},
};

TableList.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      size: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      render: PropTypes.func,
    }).isRequired
  ).isRequired,
  children: PropTypes.node,
  onChangeOrder: PropTypes.func,
};

export default TableList;
