import React from "react";
import { ThemeProvider } from "styled-components";
import MuiThemeProviderV0 from "material-ui/styles/MuiThemeProvider";
import { MuiThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import temaExact, { temaExactMuiV1, theme } from "../../utils/temaExact";

const Tema = ({ children }) => (
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={temaExactMuiV1}>
      <MuiThemeProviderV0 muiTheme={temaExact}>{children}</MuiThemeProviderV0>
    </MuiThemeProvider>
  </ThemeProvider>
);

Tema.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tema;
