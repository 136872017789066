import React from "react";

import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import Tipografia from "../Tipografia/Tipografia";
import injectSheet from "../../hocs/injectSheet/injectSheet";

const styles = {
  titulo: {
    marginLeft: 8,
    display: "flex",
    alignItems: "center",
    width: "50%",
  },
  inputStyle: {
    fontSize: "28px !important",
    height: 45,
    margin: "-4px 0 !important",
    fontWeight: 300,
    "&::placeholder": {
      fontStyle: "italic",
    },
  },
  rootInputStyle: {
    marginBottom: 3,
    marginTop: 0,
  },
};

const TituloEditavel = ({ classes, hasErrorTituloEditavel, maxLength, ...rest }) => (
  <Tipografia className={classes.titulo} tipo="titulo1">
    <TextField
      {...rest}
      id="tituloEditavelSubheader"
      fullWidth
      error={hasErrorTituloEditavel}
      inputProps={{ className: classes.inputStyle, maxLength }}
      className={classes.rootInputStyle}
      autoComplete="off"
    />
  </Tipografia>
);

TituloEditavel.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hasErrorTituloEditavel: PropTypes.bool,
  maxLength: PropTypes.number,
};

TituloEditavel.defaultProps = {
  placeholder: "Digite o título",
  hasErrorTituloEditavel: false,
};

export default injectSheet(styles)(TituloEditavel);
