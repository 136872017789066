import React from "react";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";

const DEFAULT_REGEX_MASK = /[^()\-9/\\,.]/gi;

TextMask.propTypes = {
  regexMask: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
};

function TextMask({ regexMask, ...props }) {
  const mask = regexMask || DEFAULT_REGEX_MASK;

  const onChange = e => {
    const { value } = e.target;
    props.form.setFieldValue(props.field.name, value.replace(mask, ""));
  };
  props.field.onChange = onChange;
  return <TextField {...props} />;
}

export default TextMask;
