import React from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";

import injectSheet from "../../hocs/injectSheet/injectSheet";

export const styles = {
  dropdownInput: {
    textAlign: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "14px",
  },
  dropdown: {
    width: ({ width }) => (typeof width === "number" ? `${width}px !important` : `${width} !important`),
    cursor: "pointer !important",
  },
};

const MultiSelectInput = ({ textDisplayInput, floatingLabelText, isOpen, handleChangeMenuIsOpen, classes, id }) => (
  <TextField
    id={id}
    onClick={() => handleChangeMenuIsOpen(!isOpen)}
    value={textDisplayInput}
    floatingLabelText={floatingLabelText}
    inputStyle={styles.dropdownInput}
    className={classes.dropdown}
    autoComplete="off"
  />
);

MultiSelectInput.propTypes = {
  /** Texto para ser exibido no input */
  textDisplayInput: PropTypes.string.isRequired,
  /** Label do input. */
  floatingLabelText: PropTypes.string.isRequired,
  /** Indica se o menu está aberto, se ´true´ = aberto . */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Função chamada para modificar o estado do componente: aberto = ´true´, fechado = ´false´.
   * @param {bool} open
   */
  handleChangeMenuIsOpen: PropTypes.func.isRequired,
  /** Largura do componente  */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // eslint-disable-line react/no-unused-prop-types
  /** @ignore */
  classes: PropTypes.object,
  id: PropTypes.string,
};

export default injectSheet(styles)(MultiSelectInput);
